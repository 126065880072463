/* =========
  Load Content
  A Simple Content Loader for Squarespace
  This Code is Licensed by Will-Myers.com
========== */

/*BASE STYLES*/
.wm-load-container{
  overflow: hidden;
}
.wm-load-container.full-page > *:first-child{
  padding-top: 0px !important;
}
.wm-load-container:not(.full-page) > *{
  margin: var(--outer-spacing) !important;
  padding: var(--inner-spacing) !important;
  min-height: unset !important;
  position: relative;
  box-sizing:border-box;
}
.wm-load-container .content-wrapper{
  padding: 0 !important;
}
.wm-load-container .content-wrapper > .content{
  width:100% !important;
}

.wm-load-container [data-fluid-engine="true"]{
  container-type: inline-size;
}
.wm-load-container .fluid-engine {
  grid-template-columns: 0 repeat(24, minmax(0, var(--cell-max-width))) 0;
}
@media (max-width: 767px) {
  .wm-load-container .fluid-engine {
    grid-template-columns: 0 repeat(8, minmax(0, var(--cell-max-width))) 0;
  }
}

.load-plugin.wm-alert {
  display:none;
  border: 1px dashed red;
  padding:17px;
}
.sqs-edit-mode .load-plugin.wm-alert{
  display:block;
}
.sqs-edit-mode-active [data-wm-plugin="load"]{
  border: 1px dashed;
  padding:17px;
  min-height:68px;
}
.sqs-edit-mode-active [data-wm-plugin="load"]:after{
  content:'Load Content from: ' attr(data-target) '';
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  font-weight: bold;
  font-size:14px;
  text-align:center;
}

/*Background Image Positioning*/
img.wm-image-loaded{
  --x: 50%;
  --y: 50%;
  object-fit: cover;
  object-position: var(--x) var(--y);
  width: 100%;
  height: 100%;
}

/*Inset Background Images*/
.wm-load-container section.background-width--inset .content-wrapper > * {
  padding: var(--sqs-site-gutter, 4vw);
}


/*Fluid Engine Blocks In Classic Editor*/
.sqs-grid-12 .wm-load-container .fluid-engine .sqs-block{
  padding: 0;
}

/*Full Page*/
.wm-load-container.full-page > *{
  position: relative;
  box-sizing: border-box;
}
.wm-load-container.full-page .fluid-engine {
  grid-template-columns: minmax(var(--grid-gutter), 1fr) repeat(24, minmax(0, var(--cell-max-width))) minmax(var(--grid-gutter), 1fr);
}
@media (max-width: 767px) {
  .wm-load-container.full-page .fluid-engine {
    grid-template-columns: minmax(var(--grid-gutter), 1fr) repeat(8, minmax(0, var(--cell-max-width))) minmax(var(--grid-gutter), 1fr);
  }
}
